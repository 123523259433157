import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Agenda from '../components/agenda'

const IndexPageEn = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      rando: file(relativePath: { eq: "mascotteTourisme.png" }) {
        childImageSharp {
          fixed(width: 260, height: 272) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      music: file(relativePath: { eq: "mascotteMusique.png" }) {
        childImageSharp {
          fixed(width: 260, height: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bgrando: allFile(
        filter: { relativePath: { regex: "/bg-rando/" } }
        sort: { fields: [relativePath] }
      ) {
        nodes {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      bgmusic: allFile(
        filter: { relativePath: { regex: "/bg-music/" } }
        sort: { fields: [relativePath] }
      ) {
        nodes {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <Layout lang="en" location={location}>
      <SEO
        title="Home"
        description="Excursions and hikes in the Jura. Musical entertainment: dance evenings, karaoke, ..."
        lang="en"
      />
      <div className="home columns pt-5 is-variable is-7">
        <div className="column">
          <div className="has-text-centered">
            <Img
              fixed={data.rando.childImageSharp.fixed}
              alt="Tourisme dans le Jura"
            />
          </div>
          <h2 className="has-text-centered">Excursions and outing</h2>
          <p>
            I would like to introduce you to the{" "}
            <strong>
              unusual or unmissable places{" "}
              <a href="https://fr.wikipedia.org/wiki/Jura_(d%C3%A9partement)">
                in Jura
              </a>
            </strong>
            . This will be done through{' '}
            <Link to="/en/accompagnateur-de-tourisme/#excursions">
              excursions
            </Link>{" "}
            or <Link to="/en/accompagnateur-de-tourisme/#randonnee">hikes</Link>{" "}
            accompanied and adapted to your expectations. It is pleasant to{" "}
            <strong>
              take the time to discover new landscapes, make new discoveries,
              make new meetings, getting to know craftsmen
            </strong>{" "}
            who will be able to share with you their know-how and the taste of
            the{" "}
            <Link to="/en/accompagnateur-de-tourisme/#terroir">
              Jura terroir
            </Link>
            .
          </p>
        </div>
        <div className="column is-hidden-tablet-only is-hidden-mobile">
          <div className="slider">
            {data.bgrando.nodes.map(bg => (
              <BackgroundImage fluid={bg.childImageSharp.fluid} />
            ))}
          </div>
        </div>
      </div>
      <div className="home columns pt-5 is-variable is-7">
        <div className="column is-hidden-tablet-only is-hidden-mobile">
          <div className="slider">
            {data.bgmusic.nodes.map(bg => (
              <BackgroundImage fluid={bg.childImageSharp.fluid} />
            ))}
          </div>
        </div>
        <div className="column">
          <div className="has-text-centered">
            <Img
              fixed={data.music.childImageSharp.fixed}
              alt="Animation musique"
            />
          </div>
          <h2 className="has-text-centered">Animations musicales</h2>
          <p>
            I offer you{" "}
            <Link to="/en/animation_musicale">musical entertainment</Link> and{" "}
            <Link to="/en/animation_musicale#soiree_danse">dance evenings</Link>
            , <Link to="/en/animation_musicale#karaoke">karaoke</Link> or{" "}
            <Link to="/en/animation_musicale#thes">thea</Link> dance. Whether
            for your private events such as your{" "}
            <Link to="/en/animation_musicale#mariage">wedding</Link> or an
            anniversary or public for your association or structure. All this is
            offered to you with a service and the quality of professional
            equipment adapted to your needs.
          </p>
        </div>
      </div>
      <Agenda lang="en" />
    </Layout>
  )
}

export default IndexPageEn
